import Vue from "vue"
import Vuex from "vuex"

import client from "@/lib/ApiClient"
import { axiosClient } from "@/plugins/axios"
import clientOptions from "@/plugins/axios/client_options"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    applicationInitialized: false,
    entryRoute: null,
    introspection: {},
    orderingBaseURI: null,
    subscription: null,
    subscriptionSchedule: null,
    country: null,
    token: null,
    loading: true,
    me: null,
    tenants: [],
    currentTenant: null,
    currentQuotas: null,
    selectedOrder: null,
    selectedOrderExports: null,
    orderForwardedOrders: null,
    selectedForwardedOrder: null,
    forwardedOrderForm: {
      product_code: "",
      special_requests: "",
      implants: [],
      file_uids: [],
      order_form: {}
    },
    plans: [],
    instances: [],
    features: {
      scan_analyser_tool: true,
      custom_branding: true,
      inbox: true,
      ordering_platform: true,
      communication_tool: true,
      email_support: true,
      screen_share_support: true,
      api_access: true
    },
    reloadQueue: [],
    settings: null,
    paymentMethods: null,
    orderMessages: null,
    orderApprovals: null,
    orderFiles: null,
    orderFormQuestions: null,
    clients: null,
    inboxItems: null,
    invoices: null,
    users: null,
    scanGaugeKits: null,
    gaugeKitSalesOrders: null,
    exportComponents: null
  },

  getters: {
    applicationInitialized: (state) => {
      return state.applicationInitialized
    },
    entryRoute: (state) => {
      return state.entryRoute
    },
    introspection: (state) => {
      return state.introspection
    },
    orderingBaseURI: (state) => {
      return state.orderingBaseURI
    },
    subscription: (state) => {
      return state.subscription
    },
    subscriptionSchedule: (state) => {
      return state.subscriptionSchedule
    },
    loggedIn: (state) => {
      return !!state.token
    },
    registered: (state, getters) => {
      return getters.loggedIn && !!state.me
    },
    hasTenant: (state, getters) => {
      return getters.registered && !!state.currentTenant
    },
    hasTenants: (state, getters) => {
      return getters.registered && state.tenants.length > 1
    },
    isOwner: (state) => {
      return state.currentTenant?.role === "owner"
    },
    activeSubscription: (state, getters) => {
      return (
        getters.subscription?.subscription_status === "active" &&
        !!getters.subscription?.end_time
      )
    },
    featureFlags: (state, getters) => {
      if (!getters.plans) return null
      const currentPlan = getters.plans.find(
        (p) => p.code === getters.subscription?.plan_code
      )
      return currentPlan.features
    },
    plans: (state) => state.plans,
    instances: (state) => state.instances,
    paymentMethods: (state) => state.paymentMethods,
    country: (state) => state.country,
    token: (state) => state.token,
    loading: (state) => state.loading,
    me: (state) => state.me,
    tenants: (state) => state.tenants,
    currentTenant: (state) => state.currentTenant,
    currentQuotas: (state) => state.currentQuotas,
    settings: (state) => state.settings,
    selectedOrder: (state) => state.selectedOrder,
    selectedOrderExports: (state) => state.selectedOrderExports,
    orderForwardedOrders: (state) => state.orderForwardedOrders,
    selectedForwardedOrder: (state) => state.selectedForwardedOrder,
    forwardedOrderForm: (state) => state.forwardedOrderForm,
    features: (state) => state.features,
    reloadQueue: (state) => state.reloadQueue,
    orderMessages: (state) => state.orderMessages,
    orderApprovals: (state) => state.orderApprovals,
    orderFiles: (state) => state.orderFiles,
    orderFormQuestions: (state) => state.orderFormQuestions,
    clients: (state) => state.clients,
    inboxItems: (state) => state.inboxItems,
    invoices: (state) => state.invoices,
    users: (state) => state.users,
    scanGaugeKits: (state) => state.scanGaugeKits,
    gaugeKitSalesOrders: (state) => state.gaugeKitSalesOrders,
    exportComponents: (state) => state.exportComponents
  },

  mutations: {
    SET_APPLICATION_INITIALIZED(state, payload) {
      state.applicationInitialized = payload
    },
    SET_ENTRY_ROUTE(state, payload) {
      state.entryRoute = payload
    },
    SET_INTROSPECTION(state, payload) {
      state.introspection = payload
    },
    SET_ORDERING_BASE_URI(state, payload) {
      state.orderingBaseURI = payload
    },
    SET_SUBSCRIPTION(state, payload) {
      state.subscription = payload
    },
    SET_SUBSCRIPTION_SCHEDULE(state, payload) {
      state.subscriptionSchedule = payload
    },
    SET_PLANS(state, payload) {
      state.plans = payload
    },
    SET_INSTANCES(state, payload) {
      state.instances = payload
    },
    SET_PAYMENT_METHODS(state, payload) {
      state.paymentMethods = payload
    },
    SET_COUNTRY(state, payload) {
      state.country = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ME(state, payload) {
      state.me = payload
    },
    SET_TENANTS(state, payload) {
      state.tenants = payload
    },
    SET_CURRENT_TENANT(state, payload) {
      if (!payload) return
      state.currentTenant = payload
      client.setTenantUid(payload.uid)
      const instance = state.instances.find(
        (instance) => instance.uid === payload.instance_uid
      )
      if (!instance) return
      client.setInstanceUrl(instance.url)
    },
    SET_CURRENT_QUOTAS(state, payload) {
      state.currentQuotas = payload
    },
    SET_SELECTED_ORDER(state, payload) {
      state.selectedOrder = payload
    },
    SET_SELECTED_ORDER_EXPORTS(state, payload) {
      state.selectedOrderExports = payload
    },
    SET_ORDER_FORWARDED_ORDERS(state, payload) {
      state.orderForwardedOrders = payload
    },
    SET_SELECTED_FORWARDED_ORDER(state, payload) {
      state.selectedForwardedOrder = payload
      if (payload) {
        state.forwardedOrderForm = {
          product_code: payload.product || "",
          special_requests: payload.special_requests || "",
          implants: payload.implants || [],
          file_uids: payload.file_uids || [],
          export_uid: payload.export_uid || "",
          order_form: payload.order_form || {}
        }
      } else {
        state.forwardedOrderForm = {
          product_code: "",
          special_requests: "",
          implants: [],
          file_uids: [],
          export_uid: "",
          order_form: {}
        }
      }
    },
    SET_FORWARDED_ORDER_FORM(state, payload) {
      state.forwardedOrderForm = payload
    },
    SET_FEATURES(state, payload) {
      state.features = payload
    },
    SET_TRIGGER_RELOAD(state, payload) {
      state.reloadQueue.push(payload)
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload
    },
    SET_ORDER_MESSAGES(state, payload) {
      state.orderMessages = payload
    },
    SET_ORDER_APPROVALS(state, payload) {
      state.orderApprovals = payload
    },
    SET_ORDER_FILES(state, payload) {
      state.orderFiles = payload
    },
    SET_ORDER_FORM_QUESTIONS(state, payload) {
      state.orderFormQuestions = payload
    },
    SET_CLIENTS(state, payload) {
      state.clients = payload
    },
    SET_INBOX_ITEMS(state, payload) {
      state.inboxItems = payload
    },
    SET_INVOICES(state, payload) {
      state.invoices = payload
    },
    SET_USERS(state, payload) {
      state.users = payload
    },
    SET_SCAN_GAUGE_KITS(state, payload) {
      state.scanGaugeKits = payload
    },
    SET_GAUGE_KIT_SALES_ORDERS(state, payload) {
      state.gaugeKitSalesOrders = payload
    },
    SET_EXPORT_COMPONENTS(state, payload) {
      state.exportComponents = payload
    },
    SHIFT_RELOAD_QUEUE(state) {
      state.reloadQueue.shift()
    }
  },

  actions: {
    setApplicationInitialized({ commit }, payload) {
      commit("SET_APPLICATION_INITIALIZED", payload)
    },
    setEntryRoute({ commit }, payload) {
      commit("SET_ENTRY_ROUTE", payload)
    },
    setIntrospection({ commit }, payload) {
      commit("SET_INTROSPECTION", payload)
    },
    setSubscription({ commit }, payload) {
      commit("SET_SUBSCRIPTION", payload)
    },
    setSubscriptionSchedule({ commit }, payload) {
      commit("SET_SUBSCRIPTION_SCHEDULE", payload)
    },
    setPlans({ commit }, payload) {
      commit("SET_PLANS", payload)
    },
    setInstances({ commit }, payload) {
      commit("SET_INSTANCES", payload)
    },
    setPaymentMethods({ commit }, payload) {
      commit("SET_PAYMENT_METHODS", payload)
    },
    setCountry({ commit }, payload) {
      commit("SET_COUNTRY", payload)
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload)
    },
    startLoading({ commit }) {
      commit("SET_LOADING", true)
    },
    setMe({ commit }, payload) {
      commit("SET_ME", payload)
    },
    stopLoading({ commit }) {
      commit("SET_LOADING", false)
    },
    setTenants({ commit }, payload) {
      commit("SET_TENANTS", payload)
    },
    setCurrentTenant({ commit }, payload) {
      commit("SET_CURRENT_TENANT", payload)
    },
    setCurrentQuotas({ commit }, payload) {
      commit("SET_CURRENT_QUOTAS", payload)
    },
    setSelectedOrder({ commit }, payload) {
      commit("SET_SELECTED_ORDER", payload)
    },
    setSelectedOrderExports({ commit }, payload) {
      commit("SET_SELECTED_ORDER_EXPORTS", payload)
    },
    setOrderForwardedOrders({ commit }, payload) {
      commit("SET_ORDER_FORWARDED_ORDERS", payload)
    },
    setSelectedForwardedOrder({ commit }, payload) {
      commit("SET_SELECTED_FORWARDED_ORDER", payload)
    },
    setForwardedOrderForm({ commit }, payload) {
      commit("SET_FORWARDED_ORDER_FORM", payload)
    },
    setFeatures({ commit }, payload) {
      commit("SET_FEATURES", payload)
    },
    triggerReload({ commit }, payload) {
      commit("SET_TRIGGER_RELOAD", payload)
    },
    setOrderMessages({ commit }, payload) {
      commit("SET_ORDER_MESSAGES", payload)
    },
    setOrderApprovals({ commit }, payload) {
      commit("SET_ORDER_APPROVALS", payload)
    },
    setOrderFiles({ commit }, payload) {
      commit("SET_ORDER_FILES", payload)
    },
    setOrderFormQuestions({ commit }, payload) {
      commit("SET_ORDER_FORM_QUESTIONS", payload)
    },
    setClients({ commit }, payload) {
      commit("SET_CLIENTS", payload)
    },
    setInboxItems({ commit }, payload) {
      commit("SET_INBOX_ITEMS", payload)
    },
    setInvoices({ commit }, payload) {
      commit("SET_INVOICES", payload)
    },
    setUsers({ commit }, payload) {
      commit("SET_USERS", payload)
    },
    setSettings({ commit }, payload) {
      commit("SET_SETTINGS", payload)
    },
    setScanGaugeKits({ commit }, payload) {
      commit("SET_SCAN_GAUGE_KITS", payload)
    },
    setGaugeKitSalesOrders({ commit }, payload) {
      commit("SET_GAUGE_KIT_SALES_ORDERS", payload)
    },
    setExportComponents({ commit }, payload) {
      commit("SET_EXPORT_COMPONENTS", payload)
    },
    resetData({ commit }) {
      commit("SET_PAYMENT_METHODS", null)
      commit("SET_INVOICES", null)
      commit("SET_SETTINGS", null)
      commit("SET_ORDER_FORM_QUESTIONS", null)
      commit("SET_CLIENTS", null)
      commit("SET_ORDER_MESSAGES", null)
      commit("SET_ORDER_APPROVALS", null)
      commit("SET_ORDER_FILES", null)
      commit("SET_INBOX_ITEMS", null)
      commit("SET_SELECTED_ORDER", null)
      commit("SET_CURRENT_QUOTAS", null)
      commit("SET_USERS", null)
      commit("SET_SCAN_GAUGE_KITS", null)
      commit("SET_GAUGE_KIT_SALES_ORDERS", null)
      commit("SET_SELECTED_FORWARDED_ORDER", null)
    },
    async reloadData({ getters, commit, dispatch }) {
      const nextRequest = getters.reloadQueue[0]
      commit("SHIFT_RELOAD_QUEUE")

      if (!nextRequest) return

      if (nextRequest === "fetchOrderingBaseURI" || nextRequest === "all") {
        dispatch("fetchBaseOrderingURI")
      }

      if (getters.activeSubscription) {
        if (getters.isOwner) {
          if (nextRequest === "paymentMethods" || nextRequest === "all") {
            dispatch("fetchPaymentMethods")
          }
          if (nextRequest === "invoices" || nextRequest === "all") {
            dispatch("fetchInvoices")
          }
        }
        if (nextRequest === "settings" || nextRequest === "all") {
          dispatch("fetchSettings")
        }
        if (nextRequest === "questions" || nextRequest === "all") {
          dispatch("fetchOrderFormQuestions")
        }
        if (nextRequest === "clients" || nextRequest === "all") {
          dispatch("fetchClients")
        }
        if (nextRequest === "quotas" || nextRequest === "all") {
          dispatch("fetchCurrentQuotas")
        }
        if (nextRequest === "inboxItems" || nextRequest === "all") {
          dispatch("fetchOrders")
        }
        if (nextRequest === "users" || nextRequest === "all") {
          dispatch("fetchUsers")
        }
        if (nextRequest === "gaugeKitSalesOrders" || nextRequest === "all") {
          dispatch("fetchGaugeKitSalesOrders")
        }
        if (nextRequest === "scanGaugeKits" || nextRequest === "all") {
          dispatch("fetchScanGaugeKits")
        }
      }
      if (getters.selectedOrder) {
        if (nextRequest === "order" || nextRequest === "all") {
          dispatch("fetchOrder")
        }
        if (nextRequest === "messages" || nextRequest === "all") {
          dispatch("fetchOrderMessages")
        }
        if (nextRequest === "approvals" || nextRequest === "all") {
          dispatch("fetchOrderApprovals")
        }
        if (nextRequest === "files" || nextRequest === "all") {
          dispatch("fetchOrderFiles")
        }
        if (nextRequest === "exports" || nextRequest === "all") {
          dispatch("fetchOrderExports")
        }
      }
      if (getters.selectedForwardedOrder) {
        if (nextRequest === "forwardedOrder" || nextRequest === "all") {
          dispatch("fetchForwardedOrder")
        }
      }
      if (getters.orderForwardedOrders) {
        if (nextRequest === "forwardedOrders" || nextRequest === "all") {
          dispatch("fetchForwardedOrders")
        }
      }
    },

    async fetchBaseOrderingURI({ commit }) {
      const orderingURI = await client.gateway.settings.fetchOrderingBaseURI()
      commit("SET_ORDERING_BASE_URI", orderingURI.ordering_uri)
    },

    async fetchOrderMessages({ commit, state }) {
      const messages = await client.instance.messages.fetchMessages(
        state.selectedOrder.uid
      )

      await (async () => {
        for (const message of messages) {
          if (message.attachments?.length) {
            const oftFileUids = message.attachments.map(
              (attachment) => attachment.oft_file_uid
            )

            let response
            try {
              response = await axiosClient.get(clientOptions.oftURL, {
                params: {
                  uid: oftFileUids
                }
              })
            } catch (error) {
              console.error(error)
            }

            message.attachments.forEach((attachment) => {
              const oftFile = response.data.find(
                (item) => item.uid === attachment.oft_file_uid
              )
              if (oftFile) {
                attachment.url = oftFile.oneTimeDownloadLink
              }
            })
          }
        }
      })()

      commit("SET_ORDER_MESSAGES", messages)
    },

    async fetchOrderApprovals({ commit, state }) {
      const approvals = await client.instance.messages.fetchApprovals(
        state.selectedOrder.uid
      )

      const oftFileUids = approvals.flatMap((approval) =>
        approval.files.map((file) => file.oft_file_uid)
      )
      if (oftFileUids.length) {
        let response
        try {
          response = await axiosClient.get(clientOptions.oftURL, {
            params: {
              uid: oftFileUids
            }
          })
        } catch (error) {
          console.error(error)
        }

        approvals.forEach((approval) =>
          approval.files.forEach((file) => {
            const oftFile = response.data.find(
              (item) => item.uid === file.oft_file_uid
            )
            if (oftFile) {
              file.url = oftFile.oneTimeDownloadLink
            }
          })
        )
      }
      commit("SET_ORDER_APPROVALS", approvals)
    },

    async fetchOrderFiles({ commit, state }) {
      const orderFiles = await client.instance.orders.fetchOrderFiles(
        state.selectedOrder.uid
      )
      const oftFileUids = orderFiles.map((file) => file.oft_file_uid)

      let response
      try {
        response = await axiosClient.get(clientOptions.oftURL, {
          params: {
            uid: oftFileUids
          }
        })
      } catch (error) {
        console.error(error)
      }

      orderFiles.forEach((file) => {
        const oftFile = response.data.find(
          (item) => item.uid === file.oft_file_uid
        )
        if (oftFile) {
          file.url = oftFile.oneTimeDownloadLink
        }
      })
      commit("SET_ORDER_FILES", orderFiles)
    },

    async fetchOrderFormQuestions({ commit }) {
      const orderFormQuestions = await client.instance.orders.getQuestions()
      orderFormQuestions.sort((a, b) => a.order - b.order)
      commit("SET_ORDER_FORM_QUESTIONS", orderFormQuestions)
    },

    async fetchClients({ commit }) {
      commit("SET_CLIENTS", await client.instance.clients.fetchClients())
    },

    async fetchPaymentMethods({ commit }) {
      commit(
        "SET_PAYMENT_METHODS",
        await client.gateway.billing.fetchPaymentMethods()
      )
    },

    async fetchOrders({ commit }) {
      const orders = await client.instance.orders.getOrders()
      commit("SET_INBOX_ITEMS", orders)
    },

    async fetchOrder({ commit, state }) {
      commit(
        "SET_SELECTED_ORDER",
        await client.instance.orders.getOrder(state.selectedOrder.uid)
      )
    },

    async fetchOrderExports({ commit, state }) {
      const exports = await client.instance.exports.fetchHistoryExports(
        state.selectedOrder.uid
      )

      exports.forEach(async (ex) => {
        let scanBodyResponse
        try {
          scanBodyResponse = await axiosClient.get(clientOptions.oftURL, {
            params: {
              uid: ex.scan_body_file.oft_file_uid
            }
          })
        } catch (error) {
          console.error(error)
        }
        ex.scan_body_file.url = scanBodyResponse.data[0].oneTimeDownloadLink

        if (ex.healing_cap_file) {
          let healingCapResponse
          try {
            healingCapResponse = await axiosClient.get(clientOptions.oftURL, {
              params: {
                uid: ex.healing_cap_file.oft_file_uid
              }
            })
          } catch (error) {
            console.error(error)
          }
          ex.healing_cap_file.url =
            healingCapResponse.data[0].oneTimeDownloadLink
        }
      })

      exports.sort((a, b) => b.created_at.localeCompare(a.created_at))
      commit("SET_SELECTED_ORDER_EXPORTS", exports)
    },

    async fetchCurrentQuotas({ commit }) {
      commit("SET_CURRENT_QUOTAS", await client.gateway.billing.fetchQuotas())
    },

    async fetchInvoices({ commit }) {
      const invoices = await client.gateway.billing.fetchInvoices()
      invoices.sort(
        (a, b) =>
          new Date(b.invoiced_at).getTime() - new Date(a.invoiced_at).getTime()
      )
      commit("SET_INVOICES", invoices)
    },

    async fetchUsers({ commit }) {
      const fetchTenantRolesResponse =
        await client.gateway.tenants.fetchTenantRoles()

      const users = fetchTenantRolesResponse.map((role) => {
        const phoneNumber = role.id_strings.find((item) =>
          /\+[0-9]+/.test(item)
        )
        const email = role.id_strings.find((item) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(item)
        )

        return {
          firstName: role.first_name,
          lastName: role.last_name,
          email,
          phoneNumber,
          role: role.role,
          userUid: role.user_uid,
          notify: role.notify
        }
      })
      commit("SET_USERS", users)
    },

    async fetchScanGaugeKits({ commit }) {
      const response = await client.gateway.gaugeKits.fetchScanGaugeKits()
      commit("SET_SCAN_GAUGE_KITS", response)
    },

    async fetchGaugeKitSalesOrders({ commit }) {
      commit(
        "SET_GAUGE_KIT_SALES_ORDERS",
        await client.gateway.gaugeKits.fetchGaugeKitSalesOrders()
      )
    },

    async fetchSettings({ commit }) {
      commit("SET_SETTINGS", await client.instance.tenants.fetchSettings())
    },

    async fetchForwardedOrder({ commit, state }) {
      const forwardedOrder = await client.instance.orders.getForwardedOrder(
        state.selectedOrder.uid,
        state.selectedForwardedOrder.uid
      )
      commit("SET_SELECTED_FORWARDED_ORDER", forwardedOrder)
    },

    async fetchForwardedOrders({ commit, state }) {
      const forwardedOrders = await client.instance.orders.getForwardedOrders(
        state.selectedOrder.uid
      )
      commit("SET_ORDER_FORWARDED_ORDERS", forwardedOrders)
    }
  },

  modules: {}
})

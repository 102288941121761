<template>
  <div>
    <div>
      <v-icon color="success" class="mr-2">mdi-check-circle</v-icon>
      Your case has been submitted.
    </div>
    <div v-if="loading" class="ma-4">
      <v-progress-circular indeterminate />
    </div>
    <v-list v-if="!loading">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> OSTEON CASE REFERENCE </v-list-item-title>
          <v-list-item-subtitle>
            {{ selectedForwardedOrder.case_details[0].case_mkey }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> STATUS </v-list-item-title>
          <v-list-item-subtitle>
            {{ selectedForwardedOrder.case_details[0].status }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> DUE DATE </v-list-item-title>
          <v-list-item-subtitle
            v-if="selectedForwardedOrder.case_details[0].due_date"
          >
            {{ selectedForwardedOrder.case_details[0].due_date }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="!selectedForwardedOrder.case_details[0].due_date"
          >
            Not Available
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title> IMPLANTS </v-list-item-title>
          <MultiUnitConfirmation
            v-model="selectedForwardedOrder.implants"
            :interactive="false"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title> UPLOADED FILES</v-list-item-title>
          <v-col cols="4" v-for="(file, index) in filteredFiles" :key="index">
            <v-card
              flat
              class="rounded-10 d-flex flex-column"
              height="100%"
              outlined
            >
              <v-row align="start" no-gutters>
                <v-col align="end">
                  <v-btn
                    icon
                    class="rounded-10"
                    @click.stop="() => (viewFile = file)"
                  >
                    <v-icon>mdi-arrow-expand-all</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col>
                  <v-img
                    v-if="fileType(file) === 'image'"
                    height="125"
                    contain
                    :src="file.url"
                  />
                  <ModelViewer
                    v-if="fileType(file) === 'model'"
                    height="125"
                    width="200"
                    :filename="file.file_name"
                    :url="file.url"
                  />
                  <v-card
                    v-if="
                      fileType(file) === 'download' || fileType(file) === 'html'
                    "
                    flat
                    max-height="125"
                  >
                    <v-card-text class="text-center">
                      <v-icon>mdi-information</v-icon>
                      Cannot Preview File
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-card-text class="text-center px-2">
                <p class="off rounded-10">
                  {{ file.file_name }}
                </p>
                <v-chip
                  :color="!!file.file_category ? 'success' : ''"
                  class="rounded-10 text-wrap px-1"
                  style="line-height: 15px"
                >
                  <small>
                    {{ toTitleCase(file.file_category) || "Uncategorised" }}
                  </small>
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title> SPECIAL REQUESTS </v-list-item-title>
          <v-list-item-subtitle v-if="selectedForwardedOrder.special_requests">
            {{ selectedForwardedOrder.special_requests }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <FileViewer v-model="viewFile" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { toTitleCase } from "@/utils"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation"
import ModelViewer from "@/components/shared/ModelViewer.vue"
import FileViewer from "@/components/shared/FileViewer.vue"

export default {
  name: "OrderSummary",

  components: {
    MultiUnitConfirmation,
    ModelViewer,
    FileViewer
  },

  data() {
    return {
      viewFile: null,
      loading: true
    }
  },

  computed: {
    ...mapGetters(["selectedForwardedOrder", "orderFiles"]),

    filteredFiles() {
      return this.orderFiles.filter((file) =>
        this.selectedForwardedOrder.file_uids.includes(file.uid)
      )
    }
  },

  async mounted() {
    this.triggerReload("files")
    this.checkReady()
  },

  methods: {
    ...mapActions(["triggerReload"]),

    fileType(file) {
      if (!file?.file_name) {
        return
      }
      const fileExt = file.file_name.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "html":
          return "html"
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
          return "image"
        case "ply":
        case "stl":
          return "model"
        default:
          return "download"
      }
    },

    toTitleCase(string) {
      return toTitleCase(string)
    },

    checkReady() {
      if (this.selectedForwardedOrder.case_details) {
        this.loading = false
      } else {
        this.triggerReload("forwardedOrder")
        return setTimeout(() => this.checkReady(), 1000)
      }
    }
  }
}
</script>

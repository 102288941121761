<template>
  <v-row>
    <v-col :cols="workflowStep > 1 ? 6 : 12">
      <v-card flat class="rounded-20 mx-4">
        <v-row class="ma-0">
          <v-col cols="8" class="pa-0">
            <v-card-title class="title-font">
              <v-icon large class="mr-2">$toothdesigns</v-icon> DESIGN ONLY
              ORDER
              <v-progress-circular class="ml-5" v-if="loading" indeterminate />
            </v-card-title>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-card-text class="fill-width d-flex justify-center pb-0">
              <v-img
                :src="require('@/assets/design-only-prosthesis.png')"
                class="mx-4"
                contain
                max-width="200"
              />
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-text v-if="workflowStep === 1" class="pt-0">
          Continue your order by completing the necessary options.
        </v-card-text>

        <v-card-text v-if="workflowStep === 2" class="pt-0">
          <OrderSummary />
        </v-card-text>

        <div v-if="workflowStep === 1">
          <ForwardedOrderOptions
            @back="$emit('back')"
            @next="handleStepChange(++workflowStep)"
          />
        </div>
      </v-card>
    </v-col>
    <v-col v-if="workflowStep > 1">
      <CaseChat />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ForwardedOrderOptions from "./DesignOnlyOrder/ForwardedOrderOptions"
import OrderSummary from "./DesignOnlyOrder/OrderSummary"
import CaseChat from "@/components/shared/CaseChat"
import client from "@/lib/ApiClient"

export default {
  name: "DesignOnlyOrder",

  components: {
    CaseChat,
    ForwardedOrderOptions,
    OrderSummary
  },

  data() {
    return {
      loading: false,
      workflowStep: 1,
      dialog: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "paymentMethods",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "selectedOrderExports"
    ]),

    currentStep() {
      return this.exportComplete ? 2 : 1
    },

    exportComplete() {
      return !!this.selectedOrderExports?.length
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "DESIGN")
        .method
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "setCurrentQuotas",
      "setPaymentMethods"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.workflowStep++
      this.loading = false
    }
  },

  async mounted() {
    if (this.selectedForwardedOrder) {
      if (this.selectedForwardedOrder.submitted_at) {
        this.handleStepChange(2)
      } else {
        this.handleStepChange(1)
      }
    }
  }
}
</script>

<template>
  <div>
    <ChooseOrder
      v-if="!forwardedOrderForm.product_code"
      @chooseOrder="chooseOrder"
      @cancelled="cancel"
    />
    <DesignOnlyOrder
      v-if="forwardedOrderForm.product_code === 'DESIGN'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
    <MilledBarOrder
      v-if="forwardedOrderForm.product_code === 'BAR'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import ChooseOrder from "./OrderComponent/ChooseOrder"
import MilledBarOrder from "./OrderComponent/MilledBarOrder"
import DesignOnlyOrder from "./OrderComponent/DesignOnlyOrder"

export default {
  name: "OrderComponent",

  components: { ChooseOrder, MilledBarOrder, DesignOnlyOrder },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm"
    ])
  },

  methods: {
    ...mapActions([
      "setForwardedOrderForm",
      "setSelectedForwardedOrder",
      "triggerReload"
    ]),

    async chooseOrder(code) {
      this.setForwardedOrderForm({
        ...this.forwardedOrderForm,
        product_code: code
      })
      if (!code && this.selectedForwardedOrder) {
        this.setSelectedForwardedOrder(null)
      } else {
        // this.loading = true
        let implants = [
          ...this.selectedOrder.responses.find(
            (item) => item.widget === "tooth_picker"
          ).value
        ]
        this.setForwardedOrderForm({
          ...this.forwardedOrderForm,
          implants: implants
        })
        const forwardedOrder =
          await client.instance.orders.createForwardedOrder(
            this.selectedOrder.uid,
            this.forwardedOrderForm
          )
        this.setSelectedForwardedOrder(forwardedOrder)
        // this.loading=false
      }
    },

    cancel() {
      this.$emit("cancelled")
    }
  },

  mounted() {
    if (this.$route.params.forwardedOrder) {
      this.setSelectedForwardedOrder(this.$route.params.forwardedOrder)
    }
    this.triggerReload("exports")
  }
}
</script>
